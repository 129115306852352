import { _formatMoney } from "../config/utils";
import { US_RECENT_BOOKINGS, US_SITE_POPULAR_ARRIVAL, US_SITE_POPULAR_DEPARTURE } from "../constants/Options";
import { usaPopularDestination } from "../containers/redesign/homepage/constant";

export const US_CONSTANT = {
    country: "us",
    placesAutocompletedLocation: {
        lat: 31,
        lng: -100
    },
    addressCountry: "United States",
    billingAddressSelectStates: "US",
    getPaymentFrequency: "Bi-weekly",
    passportOrigin: "US",
    bookingsEmail: "support@paylatertravel.com",
    desktopPicker: "MM/dd/yyyy",
    phoneInputWrapper: "us",
    trustpilot: "https://www.trustpilot.com/review/paylatertravel.com",
    homeflightMockup: "assets/images/paylaterflightswebusa.png",
    countryCode: "102",
    bookingReview: "https://au.trustpilot.com/review/paylatertravel.com",
    currencyCode: "USD",
    stateOrigin: "US$",
    toVisualCurrency: ({ amount }) => {
        return `US$ ${_formatMoney(amount / 100)}`;
    },
    supportMail: "support@paylatertravel.com",
    madeWithLove: "",
    privacyPolicy: "https://paylatertravel.notion.site/PLT-Privacy-Policy-USA-1206a5884d28806abbb3e97c756489c9",
    formikMuiDateFormat: "MM/DD/YYYY",
    cardRental: "https://www.gotrentalcars.com/us-label/?agence_id=5890",
    gaMeasuremntId: "G-P3RJJR6Z0Z",
    popularDestination: usaPopularDestination,
    popularDepartureDestination: US_SITE_POPULAR_DEPARTURE,
    popularArrivalDestination: US_SITE_POPULAR_ARRIVAL,
    recentBookings: US_RECENT_BOOKINGS,
    termsAndConditions: "https://paylatertravel.notion.site/United-States-Customer-Terms-and-Conditions-7e43ec2d4cd94c2988905a8a5d54e595",
    dateOfBirthExample: "12/30/1997",
    formatPhoneNumber: (phoneNumber) => {
        const phoneFormat = phoneNumber && phoneNumber.replace(/\D/g, "").match(/(\d{1})(\d{0,3})(\d{0,3})(\d{0,4})/);
        return !phoneFormat[2]
            ? phoneFormat[1]
            : `+${phoneFormat[1]} (${phoneFormat[2]})${phoneFormat[3] ? ` ${phoneFormat[3]}-${phoneFormat[4]}` : ""}`;
    },
    footerBrands: "/assets/redesign/icons/footer_stripe-us.svg",
};
