export const usaPopularDestination = [
    {
        id: 1,
        title: "Las Vegas",
        image: "assets/redesign/destinations/lasVegas.png"
    },
    {
        id: 2,
        title: "Orlando",
        image: "assets/redesign/destinations/orlando.png"
    },
    {
        id: 3,
        title: "Miami",
        image: "assets/redesign/destinations/miami.png"
    },
    {
        id: 4,
        title: "New York",
        image: "assets/redesign/destinations/nyc.png"
    },
    {
        id: 5,
        title: "San Juan",
        image: "assets/redesign/destinations/sanjuan.png"
    }
];

export const auPopularDestination = [
    {
        id: 1,
        title: "Bali",
        image: "assets/redesign/destinations/denpasar.png"
    },
    {
        id: 2,
        title: "Fiji",
        image: "assets/redesign/destinations/nadi.png"
    },
    {
        id: 3,
        title: "Auckland",
        image: "assets/redesign/destinations/auckland.png"
    },
    {
        id: 4,
        title: "Samoa",
        image: "assets/redesign/destinations/apia.png"
    },
    {
        id: 5,
        title: "Melbourne",
        image: "assets/redesign/destinations/melbourne.png"
    }
];

export const caPopularDestination = [
    {
        id: 1,
        title: "New York",
        image: "assets/redesign/destinations/nyc.png"
    },
    {
        id: 2,
        title: "London",
        image: "assets/redesign/destinations/london.png"
    },
    {
        id: 3,
        title: "Mexico",
        image: "assets/redesign/destinations/mexico.png"
    },
    {
        id: 4,
        title: "Montreal",
        image: "assets/redesign/destinations/montreal.png"
    },
    {
        id: 5,
        title: "Cuba",
        image: "assets/redesign/destinations/cuba.png"
    }
];
