import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import SlideCarouselWrapper from "../../../common/SlideCarouselWrapper";
import { CaptionTextSecondary, CurrencyText } from "../../../common/CommonStyle";
import DoneIcon from "@mui/icons-material/Done";
import Amount from "../../../common/Amount";

import { useGetWindowSize } from "../../../common/hook/WindowSize";
import { Text } from "../../../common/typography/Style";
import { ColorTheme } from "../../../../styles/color";
import { _url } from "../../../../config/utils";

const BookingStayCard = ({ propertyData, moveToStayDescription, isBookingStays }) => {
    const { mobileView } = useGetWindowSize();

    return (
        <Grid
            mt={"24px"}
            border={`1px solid ${ColorTheme.grayNeutral[200]}`}
            height={mobileView ? "auto" : 190}
            container
            bgcolor={ColorTheme.base.white}
            borderRadius={"8px"}
        >
            <Grid display={"flex"} alignItems={"center"} item md={4} xs={12}>
                <SlideCarouselWrapper
                    images={propertyData.images}
                    additionalImgStyle={{
                        height: 190
                    }}
                />
            </Grid>
            <Grid
                onClick={() => moveToStayDescription(propertyData.hotelId)}
                item
                md={8}
                xs={12}
                height={mobileView ? "auto" : 190}
                style={{ padding: 20, cursor: "pointer" }}
            >
                <Grid container justifyContent={"space-between"} spacing={"10px"}>
                    <Grid item xs={12} md={8}>
                        <Text size={"lg"} textweight={"semiBold"}>
                            {propertyData.hotelName}
                        </Text>
                        <Text size={"sm"} textcolor={ColorTheme.grayNeutral[500]} textweight={"regular"}>
                            {propertyData.region}
                        </Text>
                        <Grid marginTop={"10px"}>
                            {propertyData.paymentPlanAvailable && (
                                <CaptionTextSecondary
                                    style={{ textAlign: "justify", fontWeight: 600, color: successTextColor }}
                                    mb={1}
                                    variant="caption"
                                    fontWeight={"bold"}
                                >
                                    <DoneIcon style={{ stroke: successTextColor, strokeWidth: 2 }} /> Reserve now, pay
                                    later
                                </CaptionTextSecondary>
                            )}
                        </Grid>
                        {mobileView && (
                            <Grid item xs={12}>
                                <Box display={"flex"} justifyContent={"space-between"}>
                                    <CurrencyText
                                        style={{ display: "flex", alignItems: "baseline", gap: 5, marginBottom: 0 }}
                                    >
                                        <Amount
                                            showStrikePrice={isBookingStays}
                                            strikePrice={propertyData?.chepestRoom?.strikePrice || ""}
                                            price={propertyData?.chepestRoom?.total || 0}
                                            currency={`????`}
                                        />
                                    </CurrencyText>
                                    <Text alignItems={"center"} display={"flex"} size={"sm"} textweight={"medium"}>
                                        <img src={_url("assets/redesign/icons/star_icon.svg")} />
                                        {propertyData?.ratings?.guest?.overall && (
                                            <span>
                                                {propertyData.ratings.guest.overall}
                                                <span style={{ color: ColorTheme.grayNeutral[500] }}>/5</span>
                                            </span>
                                        )}{" "}
                                        <span style={{ color: ColorTheme.grayNeutral[500], marginLeft: 2 }}>
                                            {propertyData?.ratings?.guest?.count &&
                                                ` (${propertyData.ratings.guest.count} reviews)`}
                                        </span>
                                    </Text>
                                </Box>
                            </Grid>
                        )}
                        {!mobileView && (
                            <Grid marginTop={"10px"}>
                                <Text alignItems={"center"} display={"flex"} size={"sm"} textweight={"medium"}>
                                    <img src={_url("assets/redesign/icons/star_icon.svg")} />
                                    {propertyData?.ratings?.guest?.overall && (
                                        <span>
                                            {propertyData.ratings.guest.overall}
                                            <span style={{ color: ColorTheme.grayNeutral[500] }}>/5</span>
                                        </span>
                                    )}{" "}
                                    <span style={{ color: ColorTheme.grayNeutral[500], marginLeft: 2 }}>
                                        {propertyData?.ratings?.guest?.count &&
                                            ` (${propertyData.ratings.guest.count} reviews)`}
                                    </span>
                                </Text>
                            </Grid>
                        )}
                    </Grid>
                    {!mobileView && (
                        <Grid item xs={12} md={4}>
                            <Grid
                                display={"flex"}
                                flexDirection={"column"}
                                alignItems={"center"}
                                style={{ paddingTop: 0 }}
                            >
                                <CaptionTextSecondary variant="caption">Total</CaptionTextSecondary>
                                <CurrencyText
                                    style={{ display: "flex", alignItems: "baseline", gap: 5, marginBottom: 0 }}
                                >
                                    <Amount
                                        showStrikePrice={isBookingStays}
                                        strikePrice={propertyData?.chepestRoom?.strikePrice || ""}
                                        price={propertyData?.chepestRoom?.total || 0}
                                        currency={`????`}
                                    />
                                </CurrencyText>
                                {propertyData?.chepestRoom?.totalRooms > 1 && (
                                    <Typography mb={"5px"}>
                                        for {`${propertyData.chepestRoom.totalRooms} rooms`}
                                    </Typography>
                                )}
                                <br />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default BookingStayCard;
