import React, { useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ViewNavigation } from "../../booking-management/ViewNavigation";
import Coronavirus from "../../components/Coronavirus";
import PropertyDetailView from "../../components/stays/propertydetail/PropertyDetailView";
import HotelSearchView from "../../components/stays/result/HotelSearchView";
import {
    BookingContract,
    BookingDetail,
    Bookings,
    ChangeFlightPage,
    ConfirmBooking,
    HowItWorks,
    Login,
    PassengerDetail,
    PrivateRoute,
    RedesignFlightSearchPage,
    Refer,
    RestrictedRoute,
    ReviewPage,
} from "../LoadingView";
import BookingManagement from "../bookingdetail/redesign/BookingManagement";
import Homepage from "../redesign/homepage/Homepage";
import AboutUs from "../redesign/aboutus/AboutUs";
import FinaliseAccount from "../redesign/finaliseaccount/FinaliseAccount";
import RedesignFooter from "../redesign/footer/Footer";
import Navbar from "../redesign/navbar/Navbar";
import Review from "../redesign/review/Review";
import Testimonial from "../testimonial/Testimonial";
import { getSiteValueByLang } from "../../utils/SiteUtils";

const AppRoute = ({ auth, transparentHeader }) => {
    const navigate = useNavigate();
    const [viewModel, setViewModel] = useState({ status: "VIEW_NOT_LOADED", payload: {} });
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <>
                        <Navbar showTransparentNavbar auth={auth} />
                        <Homepage />
                        <RedesignFooter />
                    </>
                }
            />
            <Route
                path="/testimonial"
                element={
                    <Navbar auth={auth}>
                        <Testimonial />
                    </Navbar>
                }
            />
            <Route
                path="/aboutus"
                element={
                    <Navbar auth={auth}>
                        <AboutUs />
                    </Navbar>
                }
            />
            <Route
                path="/flightssearch/s/:multicity"
                element={
                    <Navbar auth={auth}>
                        <RedesignFlightSearchPage />
                    </Navbar>
                }
            />
            <Route
                path="/flightssearch/s/:origin/:destination/:departureDate/:returnDate"
                element={
                    <Navbar auth={auth}>
                        <RedesignFlightSearchPage />
                    </Navbar>
                }
            />
            <Route
                path="/flightssearch/s/:origin/:destination/:departureDate"
                element={
                    <Navbar auth={auth}>
                        <RedesignFlightSearchPage />
                    </Navbar>
                }
            />
            <Route
                path="/flightssearch/passenger"
                element={
                    <Navbar auth={auth}>
                        <PassengerDetail />
                    </Navbar>
                }
            />
            <Route
                path="/flights/review"
                element={
                    <Navbar auth={auth}>
                        <ReviewPage />
                    </Navbar>
                }
            />
            <Route
                path="/stays/search"
                element={
                    <Navbar auth={auth}>
                        <HotelSearchView />
                    </Navbar>
                }
            />
            <Route
                path="/stays/detail"
                element={
                    <Navbar auth={auth}>
                        <PropertyDetailView />
                    </Navbar>
                }
            />
            <Route
                path="/stays/checkout"
                element={
                    <Navbar auth={auth}>
                        <BookingContract />
                    </Navbar>
                }
            />
            <Route path="/terms" element={<Redirect to={getSiteValueByLang("termsAndConditions")} />} />
            <Route path="/privacy-policy" element={<Redirect to={getSiteValueByLang("privacyPolicy")} />} />
            <Route
                path="/confirm"
                element={
                    <Navbar auth={auth}>
                        <ConfirmBooking />
                    </Navbar>
                }
            />
            <Route path="/skypage" element={<Redirect to={"https://slicepay.travel/#/for-travel-agents"} />} />
            <Route path="/skypage-for-customers" element={<Redirect to={"https://slicepay.travel/"} />} />
            <Route
                path="/finaliseaccount"
                element={
                    <Navbar auth={auth}>
                        <FinaliseAccount />
                    </Navbar>
                }
            />
            <Route
                path="/reviewtrustpilot"
                element={
                    <Navbar auth={auth}>
                        <Review />
                    </Navbar>
                }
            />
            <Route
                path="/confirmbooking"
                element={
                    <Navbar auth={auth}>
                        <ConfirmBooking />
                    </Navbar>
                }
            />
            <Route
                path="/how-it-works"
                element={
                    <Navbar auth={auth}>
                        <HowItWorks />
                    </Navbar>
                }
            />
            <Route
                path="/travel-alert/coronavirus"
                element={
                    <Navbar auth={auth}>
                        <Coronavirus />
                    </Navbar>
                }
            />
            <Route
                path="/login"
                element={
                    <RestrictedRoute auth={auth}>
                        <Navbar auth={auth}>
                            <Login />
                        </Navbar>
                    </RestrictedRoute>
                }
            />
            <Route
                path="/refer"
                element={
                    <PrivateRoute auth={auth}>
                        <Navbar auth={auth}>
                            <Refer />
                        </Navbar>
                    </PrivateRoute>
                }
            />
            <Route
                path="/bookings"
                element={
                    <PrivateRoute auth={auth}>
                        <Navbar auth={auth}>
                            <Bookings />
                        </Navbar>
                    </PrivateRoute>
                }
            />
            <Route
                path="/view/:path/*"
                element={
                    <PrivateRoute auth={auth}>
                        <Navbar auth={auth}>
                            <BookingManagement
                                viewNavigation={ViewNavigation({
                                    navigate,
                                    // Hack: Remove /api because process.env.REACT_APP_API_URL is coupled to the API path
                                    targetHost: process.env.REACT_APP_BOOKING_MANAGEMENT_API_URL
                                })}
                            />
                        </Navbar>
                    </PrivateRoute>
                }
            />
            <Route
                path="/bookings/:reference"
                element={
                    <PrivateRoute auth={auth}>
                        <Navbar auth={auth}>
                            <BookingDetail />
                        </Navbar>
                    </PrivateRoute>
                }
            />
            <Route
                path="/bookings/:reference/change/flights"
                element={
                    <PrivateRoute auth={auth}>
                        <Navbar auth={auth}>
                            <ChangeFlightPage />
                        </Navbar>
                    </PrivateRoute>
                }
            />
            <Route
                path="/bookings/:reference/change/flights"
                element={
                    <PrivateRoute auth={auth}>
                        <Navbar auth={auth}>
                            <ChangeFlightPage />
                        </Navbar>
                    </PrivateRoute>
                }
            />
            <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
    );
};

export default AppRoute;

const Redirect = ({ to }) => {
    window.location.href = to;
    return null;
}
