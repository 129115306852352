// const BASE_URL = "http://plt-stays-dev-env.eba-bsctiiru.us-east-1.elasticbeanstalk.com";
const BASE_URL = process.env.REACT_APP_API_URL;
const BASE_URL_STAYS = process.env.REACT_APP_STAYS_BASE_URL;

export const getFlightChangePriceUrl = (reference) => `v2/customer/me/booking/${reference}/flight-change-price`;
export const getStaysSearchResultApiUrl = (locationText) => `${BASE_URL_STAYS}/api/v1/search/regions/${locationText}`;
export const getStaysDetailApiUrl = () => `${BASE_URL_STAYS}/api/v1/search/hotel/`;
export const getHotelDetailApiUrl = (hotelId) => `${BASE_URL_STAYS}/api/v1/hotel/details/${hotelId}`;
export const getCheckoutPage = () => `${BASE_URL_STAYS}/api/booking/room/`;
export const getPaymentInfoApiUrl = () => `${BASE_URL_STAYS}/api/booking/payment-info/`;
export const getEmailVerificationApiUrl = (email) => `${BASE_URL}/common/check-customer-exist?email=${email}`;
export const forgotPasswordApiUrl = () => `${BASE_URL}/common/forgot-password`;
export const loginUserApiUrl = () => `${BASE_URL}/v2/auth/login`;
export const getCardsApiUrl = () => `${BASE_URL}/v2/customer/me/cards`;
export const updateUserDetailsApiUrl = (bookingReference, travellerId) =>
    `${BASE_URL}/v2/customer/me/booking/${bookingReference}/editTraveller/${travellerId}`;
export const revalidateStaysApiUrl = (hotelCode) => `${BASE_URL_STAYS}/api/v1/hotel/revalidate/${hotelCode}`;
export const bookingStaysApiUrl = () => `${BASE_URL}/v2/stays/booking`;
export const revalidateCoupan = () => `${BASE_URL}/v2/stays/revalCoupon/`;
export const repaymentApiUrl = `${BASE_URL}/v2/repayments`;

export const getDashboardInfo = (bookingId) => `${BASE_URL}/v2/dashboard?bookingId=${bookingId}`;
