import { _formatMoney } from "../config/utils";
import { CA_RECENT_BOOKINGS, CA_SITE_POPULAR_ARRIVAL, CA_SITE_POPULAR_DEPARTURE } from "../constants/Options";
import { caPopularDestination } from "../containers/redesign/homepage/constant";

export const CA_CONSTANT = {
    country: "ca",
    placesAutocompletedLocation: {
        lat: 45.424721,
        lng: -75.695000
    },
    addressCountry: "Canada",
    billingAddressSelectStates: "CA",
    getPaymentFrequency: "Bi-weekly",
    passportOrigin: "CA",
    bookingsEmail: "support@paylatertravel.com",
    desktopPicker: "YYY-MM-DD",
    phoneInputWrapper: "ca",
    trustpilot: "https://www.trustpilot.com/review/paylatertravel.com",
    homeflightMockup: "assets/images/paylaterflightswebusa.png",
    countryCode: "1",
    bookingReview: "https://au.trustpilot.com/review/paylatertravel.com",
    currencyCode: "CAD",
    stateOrigin: "C$",
    toVisualCurrency: ({ amount }) => {
        return `C$ ${_formatMoney(amount / 100)}`;
    },
    supportMail: "support@paylatertravel.com",
    madeWithLove: "",
    privacyPolicy: "https://paylatertravel.notion.site/PLT-Privacy-Policy-USA-1206a5884d28806abbb3e97c756489c9",
    formikMuiDateFormat: "YYYY-MM-DD",
    cardRental: "https://www.gotrentalcars.com/us-label/?agence_id=5890",
    gaMeasuremntId: "G-P3RJJR6Z0Z",
    popularDestination: caPopularDestination,
    popularDepartureDestination: CA_SITE_POPULAR_DEPARTURE,
    popularArrivalDestination: CA_SITE_POPULAR_ARRIVAL,
    recentBookings: CA_RECENT_BOOKINGS,
    termsAndConditions: "https://paylatertravel.notion.site/United-States-Customer-Terms-and-Conditions-7e43ec2d4cd94c2988905a8a5d54e595",
    dateOfBirthExample: "1997-12-30",
    formatPhoneNumber: phoneNumber => phoneNumber,
    footerBrands: "/assets/redesign/icons/footer_stripe-us.svg"
};
