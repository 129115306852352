import { _url } from "../../../config/utils";
import { IS_USA_SITE } from "../../../constants";

export const ReviewSupport = () => (
    <div className="end max-w-640">
        <div className="checkout-feature-wrapper">
            <div>
                <img
                    className="checkout-tids"
                    style={{ width: "250px", height: "100%", cursor: "pointer" }}
                    src={_url("assets/images/trustpilot-transparent.png")}
                />
            </div>
            <br />
            <div>
                <div className="checkout-feature">
                    <img alt="support" src={_url("assets/images/support.png")} />
                    <div className="checkout-feature-content">
                        <h5>24/7 Support team</h5>
                        <p>
                            Contact us via chat or email.
                        </p>
                    </div>
                </div>
                <div className="checkout-feature">
                    <img alt="yoga" src={_url("assets/icons/yoga.png")} />
                    <div className="checkout-feature-content">
                        <h5>Flexible Interest-Free Payments</h5>
                        <p>
                            Interest-free instalments before you travel. Pick a payment plan that
                            suits your lifestyle and budget.
                        </p>
                    </div>
                </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 30 }}>
                {
                    IS_USA_SITE ? 
                        <img className="checkout-tids" alt="iata-tids" src={_url("assets/images/iata-tids-asta.svg")} /> :
                        <img className="checkout-tids" alt="iata-tids" src={_url("assets/images/iata-tids.png")} />
                }
            </div>
        </div>
    </div>
);
