import { _url } from "../config/utils";
import { FLIGHT_BEST_SORT, FLIGHT_CHEAPEST_SORT, FLIGHT_DEPOSIT_SORT, FLIGHT_WEEKLY_SORT } from "../constants";

export const GuestAgeOptions = [
    {
        singleTitle: "Adult",
        multiTitle: "Adults",
        zeroTitle: "Adult",
        value: "adults",
        age: "18+"
    },
    {
        singleTitle: "Child",
        multiTitle: "Children",
        zeroTitle: "Children",
        age: "0-17",
        value: "children"
    }
];

export const TitleOptions = [
    {
        value: "Mr.",
        text: "Mr"
    },
    {
        value: "Ms.",
        text: "Ms"
    }
];

export const FlightTitleOptions = [
    {
        value: "Mr",
        text: "Mr."
    },
    {
        value: "Mrs",
        text: "Mrs."
    },
    {
        value: "Miss",
        text: "Miss."
    },
    {
        value: "Ms",
        text: "Ms."
    },
    {
        value: "Master",
        text: "Master."
    }
];

export const GenderOptions = [
    {
        value: "m",
        text: "Male"
    },
    {
        value: "f",
        text: "Female"
    }
];

export const RatingOptions = [
    {
        value: 5
    },
    {
        value: 4
    },
    {
        value: 3
    },
    {
        value: 2
    },
    {
        value: 1
    }
];

export const GuestRatingOptions = [
    {
        value: "0",
        label: "Any"
    },
    { value: "9", label: "Wonderul 9+" },
    { value: "8", label: "Very good 8+" },
    { value: "7", label: "Good 7+" }
];

export const StarRatingOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" }
];

export const StaySortOptions = [
    {
        value: "RECOMMENDED",
        label: "Recommended"
    },
    {
        value: "PRICE_LOW_TO_HIGH",
        label: "Price Low to High"
    },
    {
        value: "PRICE_HIGH_TO_LOW",
        label: "Price High to Low"
    }
];

export const FlightSortOptions = [
    {
        value: FLIGHT_BEST_SORT,
        label: "Best"
    },
    {
        value: FLIGHT_CHEAPEST_SORT,
        label: "Cheapest"
    },
    {
        value: FLIGHT_DEPOSIT_SORT,
        label: "Lowest Deposit"
    },
    {
        value: FLIGHT_WEEKLY_SORT,
        label: "Lowest Weekly Repayment"
    }
];

export const AgeOptions = [
    {
        value: 1,
        label: "Below 2"
    },
    {
        value: 2,
        label: "2 years old"
    },
    {
        value: 3,
        label: "3 years old"
    },
    {
        value: 4,
        label: "4 years old"
    },
    {
        value: 5,
        label: "5 years old"
    },
    {
        value: 6,
        label: "6 years old"
    },
    {
        value: 7,
        label: "7 years old"
    },
    {
        value: 8,
        label: "8 years old"
    },
    {
        value: 9,
        label: "9 years old"
    },
    {
        value: 10,
        label: "10 years old"
    },
    {
        value: 11,
        label: "11 years old"
    },
    {
        value: 12,
        label: "12 years old"
    },
    {
        value: 13,
        label: "13 years old"
    },
    {
        value: 14,
        label: "14 years old"
    },
    {
        value: 15,
        label: "15 years old"
    },
    {
        value: 16,
        label: "16 years old"
    },
    {
        value: 17,
        label: "17 years old"
    }
];

export const FlightTnCOptions = [
    {
        value: "au_term",
        label: "AU Terms"
    },
    {
        value: "us_term",
        label: "US Terms"
    },
    {
        value: "au_policy",
        label: "AU Policy"
    },
    {
        value: "us_policy",
        label: "US Policy"
    }
];

export const SiteOptions = [
    {
        name: "AU",
        value: "AU"
    },
    {
        name: "US",
        value: "US"
    }
];

export const RefundableOption = [
    {
        value: "refundable",
        name: "Refundable"
    },
    {
        value: "nonRefundable",
        name: "Non-Refundable"
    }
];

export const FlightClassOption = [
    {
        value: "Y",
        name: "Economy",
        toShow: true
    },
    {
        value: "S",
        name: "Premium Economy",
        toShow: true
    },
    {
        value: "C",
        name: "Business",
        toShow: true
    },
    {
        value: "F",
        name: "First",
        toShow: true
    },
    {
        value: "P",
        name: "Premium First",
        toShow: false
    },
    {
        value: "J",
        name: "Premium Business",
        toShow: false
    }
];

export const US_SITE_POPULAR_DEPARTURE = [
    {
        id: "JFK",
        name: "John F Kennedy Intl",
        city: "New York",
        country: "United States Of America",
        state: "New York"
    },
    {
        id: "ATL",
        name: "Hartsfield-jackson Int",
        city: "Atlanta",
        country: "United States Of America",
        state: "Georgia"
    },
    {
        id: "EWR",
        name: "Newark Liberty Intl",
        city: "New York",
        country: "United States Of America",
        state: "New Jersey"
    },
    {
        id: "LAX",
        name: "Los Angeles Intl",
        city: "Los Angeles",
        country: "United States Of America",
        state: "California"
    },
    {
        id: "PHL",
        name: "Philadelphia Intl",
        city: "Philadelphia",
        country: "United States Of America",
        state: "Pennsylvania"
    }
];

export const CA_SITE_POPULAR_DEPARTURE = [
    {
        id: "YYZ",
        name: "Toronto Pearson International Airport",
        city: "Toronto",
        country: "Canada",
        state: "Ontario"
    },
    {
        id: "YVR",
        name: "Vancouver International Airport ",
        city: "Vancouver",
        country: "Canada",
        state: "British Columbia"
    },
    {
        id: "YUL",
        name: "Montréal–Pierre Elliott Trudeau International Airport ",
        city: "Montreal",
        country: "Canada",
        state: "Quebec"
    },
    {
        id: "YYC",
        name: "Calgary International Airport",
        city: "Calgary",
        country: "Canada",
        state: "Alberta"
    },
    {
        id: "YEG",
        name: "Edmonton International Airport",
        city: "Edmonton",
        country: "Canada",
        state: "Alberta"
    }
];

export const US_SITE_POPULAR_ARRIVAL = [
    {
        id: "LAS",
        name: "Mccarran International",
        city: "Las Vegas",
        country: "United States Of America",
        state: "Nevada"
    },
    {
        id: "MCO",
        name: "Orlando Intl",
        city: "Orlando",
        country: "United States Of America",
        state: "Florida"
    },
    {
        id: "MBJ",
        name: "Sangster Intl",
        city: "Montego Bay",
        country: "Jamaica",
        state: "Saint James"
    },
    {
        id: "MIA",
        name: "Miami Intl",
        city: "Miami",
        country: "United States Of America",
        state: "Florida"
    },
    {
        id: "JFK",
        name: "John F Kennedy Intl",
        city: "New York",
        country: "United States Of America",
        state: "New York"
    }
];

export const AU_SITE_POPULAR_DEPARTURE = [
    {
        id: "SYD",
        name: "Kingsford Smith",
        city: "Sydney",
        country: "Australia",
        state: "New South Wales"
    },
    {
        id: "MEL",
        name: "Melbourne Airport",
        city: "Melbourne",
        country: "Australia",
        state: "Victoria"
    },
    {
        id: "BNE",
        name: "Brisbane",
        city: "Brisbane",
        country: "Australia",
        state: "Queensland"
    },
    {
        id: "PER",
        name: "Perth",
        city: "Perth",
        country: "Australia",
        state: "Western Australia"
    },
    {
        id: "ADL",
        name: "International",
        city: "Adelaide",
        country: "Australia",
        state: "South Australia"
    }
];

export const AU_SITE_POPULAR_ARRIVAL = [
    {
        id: "DPS",
        name: "Ngurah Rai",
        city: "Denpasar-bali",
        country: "Indonesia",
        state: "Bali"
    },
    {
        id: "NAN",
        name: "Nadi Intl",
        city: "Nadi",
        country: "Fiji",
        state: "Western"
    },
    {
        id: "AKL",
        name: "Auckland International",
        city: "Auckland",
        country: "New Zealand",
        state: "Auckland"
    },
    {
        id: "APW",
        name: "Faleolo Intl",
        city: "Apia",
        country: "Samoa-independent State Of",
        state: "A`ana"
    },
    {
        id: "MEL",
        name: "Melbourne Airport",
        city: "Melbourne",
        country: "Australia",
        state: "Victoria"
    }
];

export const CA_SITE_POPULAR_ARRIVAL = [
    {
        id: "YEG",
        name: "Edmonton International Airport",
        city: "Edmonton",
        country: "Canada",
        state: "Alberta"
    },
    {
        id: "YYZ",
        name: "Toronto Pearson International Airport",
        city: "Toronto",
        country: "Canada",
        state: "Ontario"
    },
    {
        id: "YUL",
        name: "Montréal–Pierre Elliott Trudeau International Airport ",
        city: "Montreal",
        country: "Canada",
        state: "Quebec"
    },
    {
        id: "YVR",
        name: "Vancouver International Airport ",
        city: "Vancouver",
        country: "Canada",
        state: "British Columbia"
    },
    {
        id: "YYC",
        name: "Calgary International Airport",
        city: "Calgary",
        country: "Canada",
        state: "Alberta"
    }
];

export const AU_RECENT_BOOKINGS = [
    {
        id: 1,
        countryCode: "AU",
        customerName: "Cara",
        destination: "Sydney",
        arrivalDate: "2023-05-15"
    },
    {
        id: 2,
        countryCode: "UK",
        customerName: "Yannick",
        destination: "London",
        arrivalDate: "2023-06-20"
    },
    {
        id: 3,
        countryCode: "AU",
        customerName: "Leila",
        destination: "Brisbane",
        arrivalDate: "2023-08-25"
    },
    {
        id: 4,
        countryCode: "IN",
        customerName: "Gaurav",
        destination: "New Delhi",
        arrivalDate: "2023-07-10"
    },
    {
        id: 5,
        countryCode: "VN",
        customerName: "Zoey",
        destination: "Hanoi",
        arrivalDate: "2023-06-15"
    },
    {
        id: 6,
        countryCode: "NZ",
        customerName: "Jennie",
        destination: "Auckland",
        arrivalDate: "2023-09-02"
    },
    {
        id: 7,
        countryCode: "TH",
        customerName: "Lisa",
        destination: "Bangkok",
        arrivalDate: "2023-07-12"
    },
    {
        id: 8,
        countryCode: "CN",
        customerName: "Yuqi",
        destination: "Beijing",
        arrivalDate: "2023-07-15"
    },
    {
        id: 9,
        countryCode: "KR",
        customerName: "Jisoo",
        destination: "Seoul",
        arrivalDate: "2023-09-10"
    },
    {
        id: 10,
        countryCode: "AU",
        customerName: "Rose",
        destination: "Melbourne",
        arrivalDate: "2023-11-20"
    },
    {
        id: 11,
        countryCode: "ID",
        customerName: "Carlos",
        destination: "Denpasar",
        arrivalDate: "2023-08-15"
    },
    {
        id: 12,
        countryCode: "TH",
        customerName: "Minnie",
        destination: "Bangkok",
        arrivalDate: "2023-08-10"
    },
    {
        id: 13,
        countryCode: "BR",
        customerName: "Amir",
        destination: "Rio de Janeiro",
        arrivalDate: "2023-08-18"
    },
    {
        id: 14,
        countryCode: "AU",
        customerName: "Ahmed",
        destination: "Brisbane",
        arrivalDate: "2023-09-05"
    },
    {
        id: 15,
        countryCode: "JP",
        customerName: "Hiroshi",
        destination: "Tokyo",
        arrivalDate: "2023-09-10"
    },
    {
        id: 16,
        countryCode: "SA",
        customerName: "Fatima",
        destination: "Riyadh",
        arrivalDate: "2023-09-15"
    },
    {
        id: 17,
        countryCode: "KR",
        customerName: "Ruyjin",
        destination: "Seoul",
        arrivalDate: "2023-09-20"
    },
    {
        id: 18,
        countryCode: "IN",
        customerName: "Priya",
        destination: "Mumbai",
        arrivalDate: "2023-09-25"
    },
    {
        id: 19,
        countryCode: "AU",
        customerName: "Malik",
        destination: "Perth",
        arrivalDate: "2023-10-01"
    },
    {
        id: 20,
        countryCode: "SG",
        customerName: "Sofia",
        destination: "Singapore",
        arrivalDate: "2023-10-05"
    },
    {
        id: 21,
        countryCode: "FR",
        customerName: "Ahmed",
        destination: "Paris",
        arrivalDate: "2023-10-10"
    },
    {
        id: 22,
        countryCode: "AU",
        customerName: "Kira",
        destination: "Tasmania",
        arrivalDate: "2023-10-15"
    },
    {
        id: 23,
        countryCode: "US",
        customerName: "Diego",
        destination: "New York City",
        arrivalDate: "2023-10-20"
    },
    {
        id: 24,
        countryCode: "ZA",
        customerName: "Amina",
        destination: "Cape Town",
        arrivalDate: "2023-10-25"
    },
    {
        id: 25,
        countryCode: "BR",
        customerName: "Rafael",
        destination: "Sao Paulo",
        arrivalDate: "2023-10-30"
    },
    {
        id: 26,
        countryCode: "US",
        customerName: "Ananya",
        destination: "New York City",
        arrivalDate: "2023-11-05"
    },
    {
        id: 27,
        countryCode: "MX",
        customerName: "Mateo",
        destination: "Cancun",
        arrivalDate: "2023-11-10"
    },
    {
        id: 28,
        countryCode: "BR",
        customerName: "Maya",
        destination: "Rio de Janeiro",
        arrivalDate: "2023-11-15"
    },
    {
        id: 29,
        countryCode: "AE",
        customerName: "Adnan",
        destination: "Dubai",
        arrivalDate: "2023-11-20"
    },
    {
        id: 30,
        countryCode: "CA",
        customerName: "Zara",
        destination: "Toronto",
        arrivalDate: "2023-11-25"
    }
];

export const US_RECENT_BOOKINGS = [
    {
        id: 1,
        countryCode: "AU",
        customerName: "Cara",
        destination: "Sydney",
        arrivalDate: "2023-05-15"
    },
    {
        id: 4,
        countryCode: "IN",
        customerName: "Gaurav",
        destination: "New Delhi",
        arrivalDate: "2023-07-10"
    },
    {
        id: 5,
        countryCode: "VN",
        customerName: "Zoey",
        destination: "Hanoi",
        arrivalDate: "2023-06-15"
    },
    {
        id: 7,
        countryCode: "TH",
        customerName: "Lisa",
        destination: "Bangkok",
        arrivalDate: "2023-07-12"
    },
    {
        id: 8,
        countryCode: "CN",
        customerName: "Yuqi",
        destination: "Beijing",
        arrivalDate: "2023-07-15"
    },
    {
        id: 9,
        countryCode: "KR",
        customerName: "Jisoo",
        destination: "Seoul",
        arrivalDate: "2023-09-10"
    },
    {
        id: 10,
        countryCode: "AU",
        customerName: "Rose",
        destination: "Melbourne",
        arrivalDate: "2023-11-20"
    },
    {
        id: 11,
        countryCode: "ID",
        customerName: "Carlos",
        destination: "Denpasar",
        arrivalDate: "2023-08-15"
    },
    {
        id: 12,
        countryCode: "TH",
        customerName: "Minnie",
        destination: "Bangkok",
        arrivalDate: "2023-08-10"
    },
    {
        id: 13,
        countryCode: "US",
        customerName: "Amir",
        destination: "New York City",
        arrivalDate: "2023-08-18"
    },
    {
        id: 14,
        countryCode: "US",
        customerName: "Ahmed",
        destination: "Los Angeles",
        arrivalDate: "2023-09-05"
    },
    {
        id: 15,
        countryCode: "US",
        customerName: "Hiroshi",
        destination: "Las Vegas",
        arrivalDate: "2023-09-10"
    },
    {
        id: 16,
        countryCode: "US",
        customerName: "Fatima",
        destination: "Miami",
        arrivalDate: "2023-09-15"
    },
    {
        id: 17,
        countryCode: "US",
        customerName: "Santiago",
        destination: "Orlando",
        arrivalDate: "2023-09-20"
    },
    {
        id: 18,
        countryCode: "US",
        customerName: "Priya",
        destination: "Chicago",
        arrivalDate: "2023-09-25"
    },
    {
        id: 19,
        countryCode: "US",
        customerName: "Malik",
        destination: "Houston",
        arrivalDate: "2023-10-01"
    },
    {
        id: 20,
        countryCode: "US",
        customerName: "Sofia",
        destination: "Los Angeles",
        arrivalDate: "2023-10-05"
    },
    {
        id: 21,
        countryCode: "US",
        customerName: "Ahmed",
        destination: "New York City",
        arrivalDate: "2023-10-10"
    },
    {
        id: 22,
        countryCode: "US",
        customerName: "Kira",
        destination: "San Francisco",
        arrivalDate: "2023-10-15"
    },
    {
        id: 23,
        countryCode: "US",
        customerName: "Diego",
        destination: "Los Angeles",
        arrivalDate: "2023-10-20"
    },
    {
        id: 24,
        countryCode: "US",
        customerName: "Amina",
        destination: "Miami",
        arrivalDate: "2023-10-25"
    },
    {
        id: 25,
        countryCode: "BR",
        customerName: "Rafael",
        destination: "New York City",
        arrivalDate: "2023-10-30"
    },
    {
        id: 26,
        countryCode: "IN",
        customerName: "Ananya",
        destination: "Los Angeles",
        arrivalDate: "2023-11-05"
    },
    {
        id: 27,
        countryCode: "MX",
        customerName: "Mateo",
        destination: "Miami",
        arrivalDate: "2023-11-10"
    },
    {
        id: 28,
        countryCode: "JM",
        customerName: "Maya",
        destination: "Montego Bay",
        arrivalDate: "2023-11-15"
    },
    {
        id: 29,
        countryCode: "PR",
        customerName: "Adnan",
        destination: "San Juan",
        arrivalDate: "2023-11-20"
    },
    {
        id: 30,
        countryCode: "CA",
        customerName: "Zara",
        destination: "Toronto",
        arrivalDate: "2023-11-25"
    }
];

export const CA_RECENT_BOOKINGS = [
    {
        id: 1,
        countryCode: "AU",
        customerName: "Cara",
        destination: "Sydney",
        arrivalDate: "2024-11-14"
    },
    {
        id: 4,
        countryCode: "IN",
        customerName: "Gaurav",
        destination: "New Delhi",
        arrivalDate: "2025-02-22"
    },
    {
        id: 5,
        countryCode: "VN",
        customerName: "Zoey",
        destination: "Hanoi",
        arrivalDate: "2025-03-10"
    },
    {
        id: 7,
        countryCode: "TH",
        customerName: "Lisa",
        destination: "Bangkok",
        arrivalDate: "2025-01-18"
    },
    {
        id: 8,
        countryCode: "CN",
        customerName: "Yuqi",
        destination: "Beijing",
        arrivalDate: "2024-12-25"
    },
    {
        id: 9,
        countryCode: "KR",
        customerName: "Jisoo",
        destination: "Seoul",
        arrivalDate: "2025-04-03"
    },
    {
        id: 10,
        countryCode: "AU",
        customerName: "Rose",
        destination: "Melbourne",
        arrivalDate: "2025-05-14"
    },
    {
        id: 11,
        countryCode: "ID",
        customerName: "Carlos",
        destination: "Denpasar",
        arrivalDate: "2024-12-19"
    },
    {
        id: 12,
        countryCode: "TH",
        customerName: "Minnie",
        destination: "Bangkok",
        arrivalDate: "2025-02-10"
    },
    {
        id: 13,
        countryCode: "BR",
        customerName: "Amir",
        destination: "New York City",
        arrivalDate: "2024-11-05"
    },
    {
        id: 14,
        countryCode: "US",
        customerName: "Ahmed",
        destination: "Los Angeles",
        arrivalDate: "2025-01-12"
    },
    {
        id: 15,
        countryCode: "US",
        customerName: "Hiroshi",
        destination: "Las Vegas",
        arrivalDate: "2025-03-02"
    },
    {
        id: 16,
        countryCode: "US",
        customerName: "Fatima",
        destination: "Miami",
        arrivalDate: "2024-12-29"
    },
    {
        id: 17,
        countryCode: "US",
        customerName: "Santiago",
        destination: "Orlando",
        arrivalDate: "2025-04-07"
    },
    {
        id: 18,
        countryCode: "US",
        customerName: "Priya",
        destination: "Chicago",
        arrivalDate: "2024-11-01"
    },
    {
        id: 19,
        countryCode: "US",
        customerName: "Malik",
        destination: "Houston",
        arrivalDate: "2024-11-15"
    },
    {
        id: 20,
        countryCode: "US",
        customerName: "Sofia",
        destination: "Los Angeles",
        arrivalDate: "2025-03-06"
    },
    {
        id: 21,
        countryCode: "US",
        customerName: "Ahmed",
        destination: "New York City",
        arrivalDate: "2024-12-20"
    },
    {
        id: 22,
        countryCode: "US",
        customerName: "Kira",
        destination: "San Francisco",
        arrivalDate: "2025-04-25"
    },
    {
        id: 23,
        countryCode: "US",
        customerName: "Diego",
        destination: "Los Angeles",
        arrivalDate: "2025-01-15"
    },
    {
        id: 24,
        countryCode: "US",
        customerName: "Amina",
        destination: "Miami",
        arrivalDate: "2024-12-27"
    },
    {
        id: 25,
        countryCode: "BR",
        customerName: "Rafael",
        destination: "New York City",
        arrivalDate: "2025-03-18"
    },
    {
        id: 26,
        countryCode: "IN",
        customerName: "Ananya",
        destination: "Los Angeles",
        arrivalDate: "2025-02-15"
    },
    {
        id: 27,
        countryCode: "MX",
        customerName: "Mateo",
        destination: "Miami",
        arrivalDate: "2025-05-13"
    },
    {
        id: 28,
        countryCode: "JM",
        customerName: "Maya",
        destination: "Montego Bay",
        arrivalDate: "2025-01-09"
    },
    {
        id: 29,
        countryCode: "PR",
        customerName: "Adnan",
        destination: "San Juan",
        arrivalDate: "2025-05-21"
    },
    {
        id: 30,
        countryCode: "CA",
        customerName: "Zara",
        destination: "Toronto",
        arrivalDate: "2025-04-18"
    }
];

export const GB_RECENT_BOOKINGS = [
    {
        id: 1,
        countryCode: "UK",
        customerName: "Mick",
        destination: "London",
        arrivalDate: "2023-11-14"
    },
    {
        id: 4,
        countryCode: "UK",
        customerName: "Isabella",
        destination: "Manchester",
        arrivalDate: "2025-02-22"
    },
    {
        id: 5,
        countryCode: "UK",
        customerName: "Miranda",
        destination: "Glasgow",
        arrivalDate: "2025-03-10"
    },
    {
        id: 7,
        countryCode: "UK",
        customerName: "Lisa",
        destination: "Edinburgh",
        arrivalDate: "2025-01-18"
    },
    {
        id: 8,
        countryCode: "CN",
        customerName: "Yong",
        destination: "Beijing",
        arrivalDate: "2024-12-25"
    },
    {
        id: 9,
        countryCode: "KR",
        customerName: "Jisoo",
        destination: "Seoul",
        arrivalDate: "2025-04-03"
    },
    {
        id: 10,
        countryCode: "AU",
        customerName: "Rose",
        destination: "Melbourne",
        arrivalDate: "2025-05-14"
    },
    {
        id: 11,
        countryCode: "ID",
        customerName: "Ameer",
        destination: "Denpasar",
        arrivalDate: "2024-12-19"
    },
    {
        id: 12,
        countryCode: "TH",
        customerName: "Minnie",
        destination: "Bangkok",
        arrivalDate: "2025-02-10"
    },
    {
        id: 13,
        countryCode: "US",
        customerName: "Amir",
        destination: "New York City",
        arrivalDate: "2024-11-05"
    },
    {
        id: 14,
        countryCode: "US",
        customerName: "Ahmed",
        destination: "Los Angeles",
        arrivalDate: "2025-01-12"
    },
    {
        id: 15,
        countryCode: "US",
        customerName: "Hiroshi",
        destination: "Las Vegas",
        arrivalDate: "2025-03-02"
    },
    {
        id: 16,
        countryCode: "US",
        customerName: "Fatima",
        destination: "Miami",
        arrivalDate: "2024-12-29"
    },
    {
        id: 17,
        countryCode: "US",
        customerName: "Santiago",
        destination: "Orlando",
        arrivalDate: "2025-04-07"
    },
    {
        id: 18,
        countryCode: "US",
        customerName: "Priya",
        destination: "Chicago",
        arrivalDate: "2024-11-01"
    },
    {
        id: 19,
        countryCode: "US",
        customerName: "Malik",
        destination: "Houston",
        arrivalDate: "2024-11-15"
    },
    {
        id: 20,
        countryCode: "US",
        customerName: "Sofia",
        destination: "Los Angeles",
        arrivalDate: "2025-03-06"
    },
    {
        id: 21,
        countryCode: "US",
        customerName: "Ahmed",
        destination: "New York City",
        arrivalDate: "2024-12-20"
    },
    {
        id: 22,
        countryCode: "US",
        customerName: "Kim",
        destination: "San Francisco",
        arrivalDate: "2025-04-25"
    },
    {
        id: 23,
        countryCode: "US",
        customerName: "Diego",
        destination: "Los Angeles",
        arrivalDate: "2025-01-15"
    },
    {
        id: 24,
        countryCode: "US",
        customerName: "Amina",
        destination: "Miami",
        arrivalDate: "2024-12-27"
    },
    {
        id: 25,
        countryCode: "BR",
        customerName: "Rafael",
        destination: "New York City",
        arrivalDate: "2025-03-18"
    },
    {
        id: 26,
        countryCode: "IN",
        customerName: "Ananya",
        destination: "Los Angeles",
        arrivalDate: "2025-02-15"
    },
    {
        id: 27,
        countryCode: "MX",
        customerName: "Matthew",
        destination: "Miami",
        arrivalDate: "2025-05-13"
    },
    {
        id: 28,
        countryCode: "JM",
        customerName: "Josh",
        destination: "Montego Bay",
        arrivalDate: "2025-01-09"
    },
    {
        id: 29,
        countryCode: "PR",
        customerName: "John",
        destination: "San Juan",
        arrivalDate: "2025-05-21"
    },
    {
        id: 30,
        countryCode: "CA",
        customerName: "Jimmy",
        destination: "Toronto",
        arrivalDate: "2025-04-18"
    }
];

export const StopsOptions = [
    {
        value: "0",
        label: "Non Stop"
    },
    {
        value: "1",
        label: "1 stop"
    },
    {
        value: "2",
        label: "2+ stop"
    }
];

export const DepartureTimeOptions = [
    {
        id: "1",
        title: "Morning",
        subTitle: "05:00 - 11:59",
        timeRange: {
            startTime: 5,
            endTime: 12
        },
        icon: _url("assets/redesign/icons/morning.svg")
    },
    {
        id: "2",
        title: "Afternoon",
        subTitle: "12:00 - 17:59",
        timeRange: {
            startTime: 12,
            endTime: 18
        },
        icon: _url("assets/redesign/icons/afternoon.svg")
    },
    {
        id: "3",
        title: "Evening",
        subTitle: "18:00 - 11:59",
        timeRange: {
            startTime: 18,
            endTime: 24
        },
        icon: _url("assets/redesign/icons/evening.svg")
    }
];

export const ArrivalTimeOptions = [
    {
        id: "1",
        title: "Early Morning",
        subTitle: "00:00 - 04:59",
        timeRange: {
            startTime: 0,
            endTime: 5
        },
        icon: _url("assets/redesign/icons/early_morning.svg")
    },
    {
        id: "2",
        title: "Morning",
        subTitle: "05:00 - 11:59",
        timeRange: {
            startTime: 5,
            endTime: 12
        },
        icon: _url("assets/redesign/icons/morning.svg")
    },
    {
        id: "3",
        title: "Afternoon",
        subTitle: "12:00 - 17:59",
        timeRange: {
            startTime: 12,
            endTime: 18
        },
        icon: _url("assets/redesign/icons/afternoon.svg")
    },
    {
        id: "4",
        title: "Evening",
        subTitle: "18:00 - 11:59",
        timeRange: {
            startTime: 18,
            endTime: 24
        },
        icon: _url("assets/redesign/icons/evening.svg")
    }
];

export const FlightTripOptions = [
    {
        label: "Round trip",
        value: "return"
    },
    {
        label: "One Way",
        value: "one-way"
    },
    {
        label: "Multicity",
        value: "multi-city"
    }
];
