import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import React, { useCallback } from "react";
import CloseIcon from "@mui/icons-material/Close";

const CancelationPolicyInfoModal = ({ cancelationPolicies, handleClose }) => {
    const renderCancelationPolicy = useCallback((cancelationPolicy) => {
        if (cancelationPolicy?.cancelRestricted) {
            return `${cancelationPolicy.fromDateDetails} ${
                cancelationPolicy?.cancelRestricted ? ": <b>Fully non-refundable</b>" : ""
            }`;
        }
        if (cancelationPolicy["@runno"] === "0") {
            return `Until ${cancelationPolicy.toDateDetails}: ${
                cancelationPolicy.cancelCharge?.formatted === "0.00" ? "<b>Free cancelation</b>" : ""
            }`;
        }

        if (cancelationPolicy["@runno"] === "1") {
            return `Between ${cancelationPolicy.fromDateDetails} and ${cancelationPolicy.toDateDetails}
            <b>Cancelation fee of $${cancelationPolicy.cancelCharge.formatted} ???? will apply</b>
            `;
        }

        if (cancelationPolicy["@runno"] === "2") {
            return `After ${cancelationPolicy.fromDateDetails} ${
                cancelationPolicy?.cancelRestricted ? ": <b>Fully non-refundable</b>" : ""
            }`;
        }
        return "";
    }, []);

    return (
        <Dialog maxWidth={"sm"} fullWidth open onClose={handleClose}>
            <DialogTitle sx={{ m: 0, p: 2 }}>
                Cancelation policies
                {
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500]
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                }
            </DialogTitle>
            <DialogContent>
                <ul>
                    {cancelationPolicies
                        .filter((item) => !item.noShowPolicy)
                        .map((cancelationPolicy) => (
                            <li style={{ marginBottom: 20 }} key={cancelationPolicy["@runno"]}>
                                <Typography
                                    dangerouslySetInnerHTML={{
                                        __html: renderCancelationPolicy(cancelationPolicy)
                                    }}
                                />
                            </li>
                        ))}
                </ul>
            </DialogContent>
        </Dialog>
    );
};

export default CancelationPolicyInfoModal;
