/* eslint-disable arrow-body-style */
/* eslint-disable no-nested-ternary */
import moment from "moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import { FlightDetails, SearchInfo } from "../../components";
import Warnings from "../../components/checkout/Warnings";
import { DisplayText, LinkText, Text } from "../../components/common/typography/Style";
import ReviewTermsAndConditionPrompt from "../../components/review/ReviewTermsAndConditionPrompt";
import { ADD_RECENTLY_COMPLETED_BOOKING } from "../../redux/modules/bookings";
import { updateLoading } from "../../redux/modules/loading";
import { getPaymentPlan } from "../../redux/modules/paymentPlan";
import { triggerFlightReviewPageLoadedEvent } from "../../utils/events";
import { ReviewContactCard } from "./ReviewContactCard";
import { ReviewPaymentCard } from "./ReviewPaymentCard";
import { ReviewPaymentPlan } from "./ReviewPaymentPlan";
import ReviewPaymentPlanModal from "./ReviewPaymentPlanModal";
import {
    paymentSubmit,
    transformPaymentPlanPayload,
    transformPaymentPlanPayloadForTraveller,
    updateDataLayer,
    validateReviewData
} from "./Utils";
import { ReviewSupport } from "./support/ReviewSupport";

const ReviewPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openMoreInfo, setOpenMoreInfo] = useState(false);
    const searchQuery = useSelector((state) => state.search.query.flights);
    const passengers = useSelector((state) => state.passengers);
    const detailFlight = useSelector((state) => state.detailFlight);
    const paymentPlan = useSelector((state) => state.paymentPlan);
    const auth = useSelector((state) => state.auth);
    const departureDate =
        searchQuery.type === "multi-city" ? searchQuery.multiCityLegs[0].departureDate : searchQuery.departureDate;
    const [reviewData, setReviewData] = useState({
        startPaymentDate:
            paymentPlan.data.repayments?.paymentStartDate || moment().add("7", "days").format("YYYY-MM-DD"),
        error: {
            startPayment: false
        },
        showCheckTermAndConditionPrompt: false,
        plan: 1,
        isSubmmit: false,
        couponCode: paymentPlan.discount?.couponCode || "",
        credit: null
    });

    const [minimumDepositValue, setMinimumDepositValue] = useState(null);

    useEffect(() => {
        triggerFlightReviewPageLoadedEvent();
        onUpdatePaymentPlan({ ...reviewData, paymentStartDate: moment().unix() }, true, paymentPlan.data.total, true);
    }, []);

    const showLoyalityToast = (minDepositPercentage) => {
        toast.success(
            `🎉 Congratulations! Thanks to your excellent payment history, you've unlocked a special reward: a lower deposit of just ${minDepositPercentage}%! We appreciate your loyalty and are excited to offer you this exclusive benefit.`,
            {
                position: "top-center",
                autoClose: 20000,
                hideProgressBar: true,
                closeOnClick: true,
                theme: "light"
            }
        );
    };
    const sendHttpRequestUsingFetch = (url, options) => {
        const timezoneOffset = new Date().getTimezoneOffset() * -1;
        options.headers = {
            "x-access-token": auth ? auth.token : undefined,
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Timezone-Offset": timezoneOffset.toString()
        };
        return fetch(url, options);
    };
    const onUpdatePaymentPlan = (values, resetDepositPercent, paymentPlanTotal, showloyality = false) => {
        const params = transformPaymentPlanPayload(values, auth, detailFlight, departureDate);
        const makeRequest = getPaymentPlan({
            logError: console.error,
            dispatch,
            sendHttpRequest: sendHttpRequestUsingFetch
        });
        makeRequest(params).then((res) => {
            if (!resetDepositPercent) {
                return;
            }
            if (res.loyalty) {
                showloyality && showLoyalityToast(res.minDepositPercentage);
            }
            setMinimumDepositValue({
                maxDepositAmount: res.maxDepositAmount,
                maxDepositPercentage: res.maxDepositPercentage,
                minDepositAmount: res.minDepositAmount,
                minDepositPercentage: res.minDepositPercentage
            });
            setReviewData({
                ...reviewData,
                couponCode: res.discount?.couponCode || "",
                depositPercent: res.depositPercentage || res.minDepositPercentage
            });
            if (res.total !== paymentPlanTotal) {
                onUpdatePaymentPlan(
                    {
                        ...values
                    },
                    resetDepositPercent,
                    res.total
                );
            }
        });
    };

    const addBooking = (data) => {
        dispatch({
            type: ADD_RECENTLY_COMPLETED_BOOKING,
            data
        });
    };

    const onPaymentSubmit = async (paymentMethod) => {
        try {
            console.log("onPaymentSubmit");
            if (!validateReviewData(reviewData, setReviewData)) {
                console.error("startPaymentDate is null");
                toast.error("the start payment date is null", {
                    autoClose: false
                });
                return;
            }

            dispatch(updateLoading(true));
            const data = transformPaymentPlanPayloadForTraveller(
                passengers,
                detailFlight,
                reviewData,
                paymentPlan,
                searchQuery,
                paymentMethod
            );
            const res = await paymentSubmit(data, auth);

            // store the booking in our redux state
            addBooking({
                deposit: data.deposit,
                instalmentType: data.instalmentType,
                pricePerInstalment: data.pricePerInstalment / 100,
                numberOfInstalments: data.numberOfInstalments,
                reference: res.reference,
                authProviderId: res.auth_provider_id
            });
            updateDataLayer(data, reviewData.couponCode, res.reference);
            navigate("/confirm", { state: { type: reviewData.plan } });
        } catch (err) {
            if (err.response) {
                // Is an axios http status code error response, not thrown error
                console.error(err.response);
                const messageIsAnArray = err.response.data.message && err.response.data.message.length;
                const messages = messageIsAnArray ? err.response.data.message : [err.response.data.message];
                messages.forEach((message) => {
                    toast.error(message, {
                        autoClose: false
                    });
                });
            } else {
                console.error(err.message);
                toast.error(err.message, { autoClose: true });
            }
        } finally {
            dispatch(updateLoading(false));
        }
    };

    return (
        <div style={{ marginTop: 25 }}>
            <Helmet>
                <title>Review</title>
            </Helmet>
            <div className="page-content">
                <div className="content-search container-fluid content-review review">
                    <Row>
                        <Col sm="auto" xs={12}>
                            <SearchInfo searchQuery={searchQuery} />
                        </Col>
                        <Col className="content">
                            <div className="container">
                                <h3>Review</h3>
                                <Warnings items={paymentPlan.data.warnings} />
                                <FlightDetails flights={detailFlight.itineraries} type={searchQuery.type} />
                                <div className="total max-w-640" style={{ marginBottom: 30 }}>
                                    <ReviewContactCard phone={passengers.phone_number} email={passengers.email} />
                                </div>
                                <div id={"single-date-col"} className="payment-plan-wrapper total max-w-640">
                                    <DisplayText
                                        style={{ marginBottom: 12 }}
                                        textheight={"38px"}
                                        size={"sm"}
                                        textweight={"bold"}
                                    >
                                        Payment plan
                                    </DisplayText>
                                    <Text size={"sm"} textweight={"regular"}>
                                        Lock in today’s flight price
                                        {searchQuery.destination && ` to ${searchQuery.destination.city}`} by putting
                                        down a deposit. Then pay off the rest of your flight before your trip.{" "}
                                        <LinkText onClick={() => setOpenMoreInfo(true)}>More Info</LinkText>
                                    </Text>
                                    <div className="content">
                                        <ReviewPaymentPlan
                                            paymentPlan={paymentPlan.data}
                                            onUpdatePaymentPlan={onUpdatePaymentPlan}
                                            reviewData={reviewData}
                                            setReviewData={setReviewData}
                                            minimumDepositValue={minimumDepositValue}
                                        />
                                    </div>
                                    <ReviewPaymentCard
                                        paymentPlan={paymentPlan.data}
                                        onPaymentSubmit={onPaymentSubmit}
                                    />
                                </div>
                                <ReviewSupport />

                                <ReviewPaymentPlanModal
                                    open={openMoreInfo}
                                    handleClose={() => setOpenMoreInfo(false)}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            {reviewData.showCheckTermAndConditionPrompt && (
                <ReviewTermsAndConditionPrompt
                    handleClose={() =>
                        setReviewData({
                            ...reviewData,
                            showCheckTermAndConditionPrompt: false
                        })
                    }
                />
            )}
        </div>
    );
};
export default ReviewPage;
