export const sideMenu = [
    {
        id: 1,
        title: "My booking",
        subMenu: [
            {
                id: 2,
                title: "Manage my booking",
                url: "/bookings"
            },
            {
                id: 3,
                title: "FAQs",
                url: "https://help.paylatertravel.com/",
                isExternalUrl: true
            }
        ]
    },
    {
        id: 5,
        title: "Company",
        subMenu: [
            {
                id: 6,
                title: "About Us",
                url: "/aboutus"
            },
            {
                id: 7,
                title: "How it works",
                url: "/how-it-works"
            },
            {
                id: 8,
                title: "Testimonials",
                url: "/testimonial"
            },
            {
                id: 9,
                title: "Privacy Policy",
                url: "/privacy-policy",
                isExternalUrl: true
            },
            {
                id: 10,
                title: "Terms and conditions",
                url: "/terms",
                isExternalUrl: true
            }
        ]
    },
    {
        id: 11,
        title: "Connect",
        subMenu: [
            {
                id: 12,
                title: "Tiktok",
                url: "https://www.tiktok.com/@paylatertravel",
                isExternalUrl: true,
                icon: "tiktok_icon.svg"
            },
            {
                id: 13,
                title: "Facebook",
                url: "https://www.facebook.com/paylatertravel/",
                isExternalUrl: true,
                icon: "facebook_icon.svg"
            },
            {
                id: 14,
                title: "Instagram",
                url: "https://www.instagram.com/paylatertravel/",
                isExternalUrl: true,
                icon: "instagram_icon.svg"
            }
        ]
    }
];
