import { _formatMoney } from "../config/utils";
import { AU_RECENT_BOOKINGS, AU_SITE_POPULAR_ARRIVAL, AU_SITE_POPULAR_DEPARTURE } from "../constants/Options";
import { auPopularDestination } from "../containers/redesign/homepage/constant";

export const NZ_CONSTANT = {
    country: "nz",
    placesAutocompletedLocation: {
        lat: -41,
        lng: 175
    },
    addressCountry: "New Zealand",
    billingAddressSelectStates: "NZ",
    getPaymentFrequency: "Fortnightly",
    passportOrigin: "NZ",
    bookingsEmail: "support@paylatertravel.com",
    desktopPicker: "dd/MM/yyyy",
    phoneInputWrapper: "nz",
    trustpilot: "https://www.trustpilot.com/review/paylatertravel.com.au",
    homeflightMockup: "assets/images/paylaterflightsweb.png",
    countryCode: "28",
    bookingReview: "https://www.trustpilot.com/review/paylatertravel.com.au",
    currencyCode: "NZD",
    stateOrigin: "NZ$",
    toVisualCurrency: ({ amount }) => {
        return `NZ$ ${_formatMoney(amount / 100)}`;
    },
    supportMail: "support@paylatertravel.com",
    privacyPolicy: "https://paylatertravel.notion.site/PLT-Privacy-Policy-New-Zealand-11c6a5884d2880588620e011deec46ae",
    formikMuiDateFormat: "DD/MM/YYYY",
    cardRental: "https://www.driveaway.com.au/affiliates/1014025",
    gaMeasuremntId: "G-Q2KV8PSEVF", // a new GA measurement id
    popularDestination: auPopularDestination, // TODO fix with NZ content
    popularDepartureDestination: AU_SITE_POPULAR_DEPARTURE, // TODO fix with NZ content
    popularArrivalDestination: AU_SITE_POPULAR_ARRIVAL, // TODO fix with NZ content
    recentBookings: AU_RECENT_BOOKINGS, // TODO fix with NZ content
    termsAndConditions: "https://paylatertravel.notion.site/Australia-Customer-Terms-and-Conditions-b85da50e066645008bbc659e74e918df", // TODO fix with NZ content
    dateOfBirthExample: "30/12/1997",
    formatPhoneNumber: (phoneNumber) => {
        const phoneFormat = phoneNumber && phoneNumber.replace(/\D/g, "").match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,3})/);
        return !phoneFormat[2]
            ? phoneFormat[1]
            : `+${phoneFormat[1]} ${phoneFormat[2]}${phoneFormat[3] ? ` ${phoneFormat[3]} ${phoneFormat[4]}` : ""}`;
    },
    footerBrands: "/assets/redesign/icons/footer_stripe.svg"
};
