import moment from 'moment';

export const toGTMSearchData = ({ searchQuery }) => {
    const formattedDepartureDate = moment(searchQuery.departureDate, "YYYY-MM-DD");

    let formattedSearchQuery = {
        ...searchQuery,
        originIataCode:
            searchQuery.type !== "multi-city" ? searchQuery.origin.id : searchQuery.multiCityLegs[0].origin.id,
        originAirportName:
            searchQuery.type !== "multi-city" ? searchQuery.origin.name : searchQuery.multiCityLegs[0].origin.name,
        originCityName:
            searchQuery.type !== "multi-city" ? searchQuery.origin.city : searchQuery.multiCityLegs[0].origin.city,
        originCountry:
            searchQuery.type !== "multi-city" ? searchQuery.origin.country : searchQuery.multiCityLegs[0].origin.country,
        departureDayOfYear: formattedDepartureDate.dayOfYear(),
        departureWeekOfYear: formattedDepartureDate.week(),
        departureMonth: formattedDepartureDate.month() + 1,
        departureYear: formattedDepartureDate.year(),
        departureEpoch: formattedDepartureDate.unix(),
        isFamily: !!(searchQuery.children > 0 || searchQuery.infants > 0),
        destinationIataCode:
            searchQuery.type !== "multi-city"
                ? searchQuery.destination.id
                : searchQuery.multiCityLegs[0].destination.id,
        destinationAirportName:
            searchQuery.type !== "multi-city"
                ? searchQuery.destination.name
                : searchQuery.multiCityLegs[0].destination.name,
        destinationCityName:
            searchQuery.type !== "multi-city"
                ? searchQuery.destination.city
                : searchQuery.multiCityLegs[0].destination.city,
        destinationCountry:
            searchQuery.type !== "multi-city"
                ? searchQuery.destination.country
                : searchQuery.multiCityLegs[0].destination.country,
    };

    const formattedReturnDate = moment(searchQuery.returnDate, "YYYY-MM-DD");
    if (searchQuery.type !== "one-way") {
        formattedSearchQuery = {
            ...formattedSearchQuery,
            returnDayOfYear: formattedReturnDate.dayOfYear(),
            returnWeekOfYear: formattedReturnDate.week(),
            returnMonth: formattedReturnDate.month() + 1,
            returnYear: formattedReturnDate.year(),
            returnEpoch: formattedReturnDate.unix()
        };
    }
    return formattedSearchQuery;
}
